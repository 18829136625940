<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="none" name="plantCd" v-model="searchParam.plantCd" @input="getList"/>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :required="true"
            type="year"
            default="today"
            label="기준년도"
            name="wasteYear"
            @input="getList"
            v-model="searchParam.wasteYear"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-checkbox
            :isFlag="true"
            label="그래프 라벨표시"
            name="checkPoint"
            v-model="checkPoint"
            @datachange="checkPointDatachange">
          </c-checkbox>
        </div>
      </template>
    </c-search-box>
    <div class="col-12" style="background:#fff;">
      <apexchart 
        ref="chart1" 
        height="300" 
        type="line"
        :width="chart.chartWidth"
        :options="chart.chartOptions" 
        :series="chart.series"></apexchart>
    </div>
    <br>
    <div class="col-12" style="background:#fff;">
      <apexchart 
        ref="chart3" 
        height="300" 
        type="line"
        :width="chart3.chartWidth"
        :options="chart3.chartOptions" 
        :series="chart3.series"></apexchart>
    </div>
    <br>
    <div class="col-12" style="background:#fff;">
      <apexchart 
        ref="chart2" 
        height="300" 
        type="line"
        :width="chart2.chartWidth"
        :options="chart2.chartOptions" 
        :series="chart2.series"></apexchart>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import VueApexCharts from "vue-apexcharts";
export default {
  name: 'waste-uc-manage',
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      checkPoint: 'N',
      searchParam: {
        wasteYear: '',
        plantCd: null,
      },
      chart3: {
        chartOptions: {
          title: {
            text: '폐기물 원단위 분석 - 1대당 처리비용(원)'
          },
          chart: {
            type: 'line',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2
            },
            toolbar: {
              show: true
            },
            zoom: {
              enabled: false
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
          },
          xaxis: {
            categories: ['1월','2월','3월','4월','5월','6월','7월','8월','9월','10월','11월','12월'],
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          yaxis: {
            title: {
              text: '1대당 처리비용(원)'
            }
          },
          fill: {
            opacity: 1
          },
          markers: {
            size: 1,
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
          }
        },
        series: [],
        chartWidth: '100%',
      },
      chart: {
        chartOptions: {
          title: {
            text: '폐기물 원단위 분석 - 1대당 발생량(kg)'
          },
          chart: {
            type: 'line',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2
            },
            toolbar: {
              show: true
            },
            zoom: {
              enabled: false
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
          },
          xaxis: {
            categories: ['1월','2월','3월','4월','5월','6월','7월','8월','9월','10월','11월','12월'],
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          yaxis: {
            title: {
              text: '1대당 발생량(kg)'
            }
          },
          fill: {
            opacity: 1
          },
          markers: {
            size: 1,
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
          }
        },
        series: [],
        chartWidth: '100%',
      },
      chart2: {
        chartOptions: {
          title: {
            text: '폐기물 처리비용(원)'
          },
          chart: {
            type: 'line',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2
            },
            toolbar: {
              show: true
            },
            zoom: {
              enabled: false
            },
          },
          dataLabels: {
            enabled: false,
            formatter: function(value) {
              var val = Math.abs(value)
              if (val >= 1000) {
                val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              return val
            }
          },
          stroke: {
            curve: 'straight',
          },
          xaxis: {
            categories: ['1월','2월','3월','4월','5월','6월','7월','8월','9월','10월','11월','12월'],
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          yaxis: {
            title: {
              text: '처리비용(원)'
            },
            labels: {
              formatter: function(value) {
                var val = Math.abs(value)
                if (val >= 1000) {
                  val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                return val
              }
            }
          },
          fill: {
            opacity: 1
          },
          markers: {
            size: 1,
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            // formatter: function(label, opts) {
            //   return label + " - " + opts.w.globals.series[opts.seriesIndex]
            // }
          }
        },
        series: [],
        chartWidth: '100%',
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.env.waste.volume.uc.url;
      this.getList();
    },
    checkPointDatachange() {
      if (this.checkPoint == 'Y') {
        this.$set(this.chart.chartOptions.dataLabels, 'enabled', true)
        this.$set(this.chart2.chartOptions.dataLabels, 'enabled', true)
        this.$set(this.chart3.chartOptions.dataLabels, 'enabled', true)
      } else {
        this.$set(this.chart.chartOptions.dataLabels, 'enabled', false)
        this.$set(this.chart2.chartOptions.dataLabels, 'enabled', false)
        this.$set(this.chart3.chartOptions.dataLabels, 'enabled', false)
      }
      this.$refs['chart1'].refresh();
      this.$refs['chart2'].refresh();
      this.$refs['chart3'].refresh();
    },
    getList() {
      let isAllSearch = false;
      if (this.searchParam.plantCd.split(',').length > 1) {
        isAllSearch = true;
      }
      if (this.searchParam.wasteYear !== null) {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = this.searchParam;
        this.$http.request((_result) => {
          if (isAllSearch) {
            // this.grid.data = this.$_.filter(_result.data, {tvTypeCdTop : 'A' });
            // this.grid2.data = this.$_.filter(_result.data, {tvTypeCdTop : 'F' });
            // this.grid3.data = this.$_.filter(_result.data, {tvTypeCdTop : 'O' });
            // this.grid4.data = this.$_.filter(_result.data, {tvTypeCdTop : 'J' });
            // this.grid5.data = this.$_.filter(_result.data, {tvTypeCdTop : 'Q' });
          } else {
            let sdata = this.$_.filter(_result.data, {tvTypeCdTop : 'O', tvTypeCd : 'O', totalFlag: 'X' });
            this.chart.series = [];
            this.$_.forEach(sdata, item => {
              this.chart.series.push({
                name:item.envWasteMstName,
                data: [item.tv01,item.tv02,item.tv03,item.tv04,item.tv05,item.tv06,item.tv07,item.tv08,item.tv09,item.tv10,item.tv11,item.tv12]
              })
            })
            
            let sdata3 = this.$_.filter(_result.data, {tvTypeCdTop : 'O', tvTypeCd : 'P', totalFlag: 'X' });
            this.chart3.series = [];
            this.$_.forEach(sdata3, item => {
              this.chart3.series.push({
                name:item.envWasteMstName,
                data: [item.tv01,item.tv02,item.tv03,item.tv04,item.tv05,item.tv06,item.tv07,item.tv08,item.tv09,item.tv10,item.tv11,item.tv12]
              })
            })
            
            let sdata2 = this.$_.filter(_result.data, {tvTypeCdTop : 'J', totalFlag: 'X' });
            this.chart2.series = [];
            let sum01 = 0;
            let sum02 = 0;
            let sum03 = 0;
            let sum04 = 0;
            let sum05 = 0;
            let sum06 = 0;
            let sum07 = 0;
            let sum08 = 0;
            let sum09 = 0;
            let sum10 = 0;
            let sum11 = 0;
            let sum12 = 0;
            this.$_.forEach(sdata2, item => {
              this.chart2.series.push({
                name:item.envWasteMstName,
                data: [item.tv01,item.tv02,item.tv03,item.tv04,item.tv05,item.tv06,item.tv07,item.tv08,item.tv09,item.tv10,item.tv11,item.tv12]
              })
              sum01 += Number(item.tv01);
              sum02 += Number(item.tv02);
              sum03 += Number(item.tv03);
              sum04 += Number(item.tv04);
              sum05 += Number(item.tv05);
              sum06 += Number(item.tv06);
              sum07 += Number(item.tv07);
              sum08 += Number(item.tv08);
              sum09 += Number(item.tv09);
              sum10 += Number(item.tv10);
              sum11 += Number(item.tv11);
              sum12 += Number(item.tv12);
            })
            this.chart2.series.push({
              name: '전체비용',
              data: [sum01,sum02,sum03,sum04,sum05,sum06,sum07,sum08,sum09,sum10,sum11,sum12]
            })
          }
          this.$refs['chart1'].refresh();
          this.$refs['chart2'].refresh();
          this.$refs['chart3'].refresh();
        },);
      } else {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message:
          '기준년도를 선택하세요',
          type: 'warning', // success / info / warning / error
        });
      }
    },
  }
};
</script>
